/* Reusable functions */
import { post } from '../services/API';

export const numberFormatPound = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  maximumFractionDigits: 0
});

export const numberFormat = new Intl.NumberFormat('en-GB', {
  maximumFractionDigits: 0
});

export function snakeCaseToEnglish(inputString, prefixToRemove = null) {
  const stringWithoutPrefix = inputString.startsWith(prefixToRemove)
    ? inputString.slice(prefixToRemove.length + 1)
    : inputString;
  const words = stringWithoutPrefix.split('_');
  return words
    .map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word))
    .join(' ');
}

export function capitalizeFirstLetter(string) {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
}

export const convertToTitleCase = (inputString) => {
  if (typeof inputString !== 'string') {
    return inputString;
  } else {
    const words = inputString.split('_').map((word) => word.charAt(0) + word.slice(1));
    const titleCaseString = words.join(' ');
    return titleCaseString.charAt(0).toUpperCase() + titleCaseString.slice(1);
  }
};

export const generateUniqueID = () => {
  return Math.floor(Math.random() * Date.now());
};

export function formatDate(startTimeString) {
  const startTime = new Date(startTimeString);
  const hours = startTime.getHours();
  const minutes = startTime.getMinutes();
  const period = hours >= 12 ? 'pm' : 'am';
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
  return `${formattedHours}:${formattedMinutes} ${period}`;
}

export const convertDate = (isoDateString) => {
  const dateObject = new Date(isoDateString);

  // Get UTC components and format manually
  const day = dateObject.getUTCDate();
  const month = dateObject.toLocaleString('en-GB', { month: 'long', timeZone: 'UTC' });
  const hours = dateObject.getUTCHours();
  const minutes = dateObject.getUTCMinutes().toString().padStart(2, '0');

  return `${day} ${month} at ${hours}:${minutes}`;
};

export const getUploadFormValues = (values) => {
  const tempAnswer = {};
  for (const value in values) {
    if (typeof values[value] === 'object') {
      const files = values[value];
      if (typeof files[0] === 'string') {
        tempAnswer[value] = [files];
      } else {
        const uploadedFileKeys = files.map((file) => file.name);
        tempAnswer[value] = uploadedFileKeys.length > 0 ? [uploadedFileKeys] : null;
      }
    } else {
      tempAnswer[value] = values[value];
    }
  }
  return tempAnswer;
};
export const getInstallerfetchUrl = async (installer) => {
  try {
    const image = installer.logo.replace(/["{}]/g, '');
    const obj = {
      key: installer.installer_id,
      fileName: `logo${image}`
    };

    const res = await post('form', `getInstallerfetchUrl`, obj);
    const response = await fetch(res.data);
    return response.url;
  } catch (error) {
    console.error('Error fetching documents:', error);
  }
};
export const convertDateTime = (isoDateString, options = {}) => {
  const {
    locale = 'en-GB',
    showWeekday = false,
    dayFormat = 'numeric',
    monthFormat = 'long',
    showYear = true,
    yearFormat = 'numeric',
    dateStyle = 'long', // New option: 'long' or 'short'
    showDate = true,
    showTime = true,
    timeOptions = { hour: 'numeric', minute: '2-digit', hour12: true },
    separator = '–',
    timezone = 'UTC'
  } = options;

  const dateObject = new Date(isoDateString);

  if (isNaN(dateObject.getTime())) {
    return 'Invalid Date';
  }

  let dateOptions;

  if (dateStyle === 'short') {
    dateOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    };
  } else {
    dateOptions = {
      day: dayFormat,
      month: monthFormat
    };

    if (showWeekday) {
      dateOptions.weekday = 'long';
    }

    if (showYear) {
      dateOptions.year = yearFormat;
    }
  }

  let formattedDate = dateObject.toLocaleDateString(locale, {
    ...dateOptions,
    timeZone: timezone
  });

  if (showWeekday) {
    const [weekday, ...rest] = formattedDate.split(' ');
    formattedDate = `${weekday}, ${rest.join(' ')}`;
  }

  const formattedTime = showTime
    ? dateObject.toLocaleTimeString(locale, { ...timeOptions, timeZone: timezone }).toLowerCase()
    : '';

  //return `${formattedDate}${showTime ? ` ${separator} ` : ''}${formattedTime}`;
  return `${showDate ? formattedDate : ''}${showDate && showTime ? ` ${separator} ` : ''}${formattedTime}`;
};

export const handleDownloadFile = async (url, fileName) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      responseType: 'blob'
    });
    const blob = await response.blob();
    const tempLink = document.createElement('a');
    tempLink.href = window.URL.createObjectURL(blob);
    tempLink.setAttribute('download', fileName.replace(/\.$/, ''));
    tempLink.setAttribute('target', '_blank');
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

export const validateUKPhoneNumber = (input) => {
  // Remove spaces for validation purposes
  let sanitizedInput = input.replace(/\s+/g, '');

  // If the input starts with +44, remove the prefix
  if (sanitizedInput.startsWith('+44')) {
    sanitizedInput = sanitizedInput.replace(/^\+44/, '');
  }
  // If the input starts with 0044, remove the prefix
  else if (sanitizedInput.startsWith('0044')) {
    sanitizedInput = sanitizedInput.replace(/^0044/, '');
  }
  // If the input starts with 0, remove the leading 0
  else if (sanitizedInput.startsWith('0')) {
    sanitizedInput = sanitizedInput.replace(/^0/, '');
  }

  // Check if the remaining input is 10 digits
  if (/^\d{10}$/.test(sanitizedInput)) {
    return { isValid: true, error: '', sanitizedContactNumber: sanitizedInput };
  } else {
    return {
      isValid: false,
      error: 'Phone number must have 10 digits after the country code or leading zero.'
    };
  }
};
