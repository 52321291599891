import './Button.style.css';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

export default function CustomButton(props) {
  const { cssType, label, icon, small, noMargin, iconMove, extraClasses, disabled, ...rest } =
    props;
  const buttonProps = {
    className: `${small ? 'btn-small' : 'btn'} ${cssType} ${extraClasses ? extraClasses : ''}`,
    label,
    disabled,
    ...rest
  };

  return (
    <button {...buttonProps} style={noMargin && { margin: '0px' }}>
      <div className={'btn-label'}>
        <div className={`${small ? 'text-small' : 'text-standard'}`}>{label}</div>

        {icon && (
          <div className="btn-icon" style={{ marginLeft: '1rem', display: 'flex' }}>
            {/* <img src={icon} alt="Button icon" /> */}
            {icon}
          </div>
        )}
      </div>
    </button>
  );
}

CustomButton.propTypes = {
  cssType: PropTypes.string,
  small: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.element,
  disabled: PropTypes.bool
};
